export default {
  es: {
    title:{
      'call_waiter': 'LLamar al mesero',
      'see_menu': 'Ver el menú',
      'request_account': 'Solicitar la cuenta',
      'place_order': 'Hacer el pedido',
      'see_order': 'Ver el pedido',
      'table': 'Mesa | Mesas',
      'new_table': 'Nueva mesa',
      'waiter': 'Mesero | Meseros',
      'client': 'Cliente:',
      'invited':'Invitado',
      'menu':'Menú',
      'number_of_accounts':'Numero de cuentas:',
      'product': 'Producto | Productos',
      'price':'Precio | Precios',
      'amount':'Importe',
      'confirm_products': 'Confimar pedido',
      'pay_account': 'Pagar la cuenta',
      'pay': 'Pagar',
      'make_payment': 'Realizar el pago',
      'add_products' : 'Agregar',
      'removed_product':'Eliminar',
      'updated_product':'Actualizar',
      'special_requests':'Solicitudes especiales',
      'back': 'Atras',
      'account':'Cuenta | Cuentas',
      'see_account':'Ver cuenta',
      'total':'Total',
      'quantity':'Cantidad',
      'confirmed_product':'Producto confirmado',
      'return_menu':'Regresar al menú',
      'return_account':'Regresar al pedido',
      'comments': 'Comentarios',
      'next_template': 'Siguiente template',
      'extras' : 'EXTRA | EXTRAS',
      'of':'DE',
      'without_extras':'Sin extras',
      'edit_product' : 'EDITAR PRODUCTO',
      'see_product' : 'VER PRODUCTO',
      'choose_option': 'Tienes opcion de selecionar hasta ',
      'type_of': ' tipo(s) de',
      'choose_your': 'Seleciona tus',
      'select_product_package': 'Seleccione los productos que incluirá el paquete',
      'select_product':'Seleccione un producto',
      'package' : 'PAQUETE | PAQUETES',
      'lang':'Idioma',
      
      
    },
   
    info: {
      'wait': 'Espera un momento...',
      'connecting': 'Conectar',
      'loading': 'Cargando datos...',
      'welcome': 'Bienvenido',
      'to':'a',
      'welcome_customer': 'Bienvenido estimado cliente! Introduzca un nombre para identificarlo:',
      'thank_preference' : '¡Muchas gracias por tu preferencia!',
      'come_back':'¡Vuelva pronto!',
      'redirecting_in': 'Redirigiendo en',
      'second':'Segundo(s)...',
      'opss' : 'Ooops!',
      'menu_not_available': 'El menú no esta disponible en este momento.',
      'table_in_service': 'Esta mesa ya está en servicio, por favor póngase en contacto con el gerente a cargo.',
      'requested_restaurant':'No se encontró el restaurante solicitado.',
      'table_not_found':'No se encontro la mesa solicitada o ya ha sido cerrada.',
      'account_closed':'(Cerrado)',
      'account_locked':'(Bloqueado)',
      'account_cancelled':'(Cancelado)',
      'incomplete_configuration':'Configuración incompleta, debe confirmar',
      'loading_menu':'Espera, cargando menú',
      'confirm_selection':'Confirmar',
      'cancel':'Cancelar',
      'back_product':'Volver al producto',
      'num_max_product':'El numero máximo de productos es:',
      'select_options':'Seleccione una de estas dos opciones',
      'consumption_note':'Nota de consumo',
      'suggested_tips':'Propina sugerida',
      'info_the_pay':'Información de Pago',
      'paypal':'Paypal',
     
    },
    messages: {
      'call_made_staff':'Llamada de servicio realizada al personal',
      'action_type_menu':'En un momento le ofrecemos la carta',
      'action_type_bill':'El ticket de la mesa está en camino',
      'action_type_order':'En un momento se acerca un mesero para tomar su orden',
      'not_connected_table': 'No conectado a la mesa, consulte con el gerente en turno',
      'not_reservation_loaded': 'Los datos de la reserva no se cargaron correctamente',
      'invalid_code': 'Código inválido. Revise de nuevo.',
      'error_code': 'Error al validar el código.',
      'not_product_removed':'No se pudo eliminar el producto:',
      'not_change_quantity': 'No se pudo cambiar la cantidad:',
      'error_confirming_products':'Error al confirmar los productos:',
      'saved_product':'Producto agregado correctamente',
      'updated_product':'El producto fue actualizado correctamente',
      'saved_package':'Paquete agregado correctamente',
      'not_saved_product':'Producto no agregado:',
      'not_saved_package':'Paquete no agregado:',
      'not_updated_product':'No se pudo editar el producto',
      'error_reload_accounts': 'Error al recargar las cuentas',
      'cancel_pay':'Se ha cancelado el pago.',
      'wait_save_pay':'Espere, guardando datos de pago...',
      'wait_save_pay_account':'Espere, Estamos guardando el pago en la cuenta...',
      'thanks_close_account':'Muchas gracias su pago fue exitoso, Estamos cerrando la cuenta en breve recibira un correo del pago...'
    }
  }
}
